<template>
    <div class="AuditLog">
        <div class="AuditLog__container">
            <radio-button class="AuditLog__header"
                          v-model="option.query.onlyFail"
                          :active-style="{background: '#1677FF', color: '#FFFFFF'}"
                          :column="2"
                          :inactive-style="{background: '#FFFFFF', color: '#323233', border: 'none'}"
                          :items="items"
                          @change="onSearch"
            />
            <div class="AuditLog__body">
                <my-scroll-list ref="list" :option="option">
                    <template #item="{item}">
                        <div class="AuditLog__item Item">
                            <div class="Item__title">
                                <span style="font-size: 16px; font-weight: 600;">{{ item.batchName }}</span>
                                <span :style="{fontSize: '12px', color: getColor(item.auditStatus), marginLeft: '4px'}">
                                    {{ item.auditStatus | getMapText('number/materialAuditStatus') }}
                                </span>
                            </div>
                            <div class="Item__desc">
                                <div class="Item__descLabel">审核类型</div>
                                <div class="Item__descValue">
                                    {{ item.materialType | getMapText('openOrder/materialType') }}
                                </div>
                            </div>
                            <div class="Item__desc">
                                <div class="Item__descLabel">创建时间</div>
                                <div class="Item__descValue">{{ item.createTime }}</div>
                            </div>
                            <div v-if="['-1', '-2'].includes(item.auditStatus)" class="Item__desc">
                                <div class="Item__descLabel">失败原因</div>
                                <div class="Item__descValue">{{ item.auditRemark }}</div>
                            </div>
                        </div>
                    </template>
                </my-scroll-list>
            </div>
        </div>
        <div class="AuditLog__search">
            <van-search v-model="option.query.batchName" placeholder="请输入400号码，支持模糊搜索" @search="onSearch" />
        </div>
    </div>
</template>

<script>
    import RadioButton from "@/components/RadioButton";
    import {MyScrollList} from "@/components/MyPlugins";
    import {numHoldMaterialListNew} from "@/api/openOrder";

    export default {
        name: "AuditLog",
        components: {RadioButton, MyScrollList},
        data() {
            return {
                items: [
                    {text: '仅看失败', value: 1},
                    {text: '查看全部', value: 2},
                ],
                option: {
                    api: numHoldMaterialListNew,
                    query: {
                        batchName: '',
                        onlyFail: 1
                    }
                }
            }
        },
        methods: {
            onSearch() {
                this.$refs.list.onSearch();
            },
            getColor(auditStatus) {
                if (['-1', '-2'].includes(auditStatus)) {
                    return '#EE0A24';
                } else if (['1','2'].includes(auditStatus)) {
                    return '#81B337';
                } else {
                    return '#969799';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .AuditLog {
        height: 100vh;
        background: #F7F8FA;
        display: flex;
        flex-direction: column;
        &__container {
            flex: 1;
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        &__body {
            flex: 1;
            overflow: auto;
            margin-top: 10px;
        }
        &__item {
            border-radius: 4px;
            background: #FFFFFF;
            margin-bottom: 10px;
            padding: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .Item {
            &__title {
                line-height: 30px;
                margin-bottom: 4px;
                display: flex;
                align-items: center;
            }
            &__desc {
                display: flex;
                font-size: 12px;
                gap: 0 8px;
                line-height: 18px;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
                &Label {
                    width: 48px;
                    color: #969799;
                }
                &Value {
                    flex: 1;
                    color: #323233;
                }
            }
        }
    }
</style>
