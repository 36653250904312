<template>
    <div class="RadioButton">
        <van-button v-for="item in items"
                    :key="item.value"
                    :style="styles(item.value)"
                    native-type="button"
                    size="normal"
                    type="default"
                    @click="onUpdate(item.value)"
        >{{ item.text }}</van-button>
    </div>
</template>

<script>
    export default {
        name: "RadioButton",
        props: {
            value: [String, Number],
            items: Array,
            column: {
                type: Number,
                default: 4
            },
            activeStyle: {
                type: Object,
                default: () => {
                    return {
                        color: '#1677FF',
                        background: '#FFFFFF',
                        border: '1px solid #1677FF'
                    }
                }
            },
            inactiveStyle: {
                type: Object,
                default: () => {
                    return {
                        color: '#969799',
                        background: '#F5F6F8',
                        border: 'none'
                    }
                }
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        methods: {
            styles(value) {
                let n = this.column - 1;
                let style = this.value === value ? this.activeStyle : this.inactiveStyle;

                return {width: `calc((100% - ${n * 10}px) / ${this.column})`, ...style};
            },
            onUpdate(value) {
                this.$emit('change', value);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .RadioButton {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        .van-button {
            font-size: 12px;
            white-space: nowrap;
            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>
